type LogLevel = "trace" | "debug" | "info" | "warn" | "error" | "silent";

// Merge config params into global Window interface
declare global {
    interface Window {
        BOOKING_API_URL: string;
        CONFERENCE_CONTROL_API_URL: string;
        CONFERENCE_PLAYBACK_BOT_API_URL: string;
        PHONE_NUMBER_API_URL: string;
        REGISTRATION_UI_URL: string;
        ADMIN_UI_URL: string;
        CONFERENCING_ENABLE_VIDEO: string;
        TWILIO_ACCOUNT_SID: string;
        CONFERENCING_DEBUG_LEVEL: string;
        DEBUG_TWILIO_VIDEO: string;
        SENTRY_ENVIRONMENT: string;
        SENTRY_TARGET_CONFERENCE_UI: string;
        SENTRY_TRACE_SAMPLE_RATE: string;
        SENTRY_REPLAY_SESSION_SAMPLE_RATE: string;
        SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE: string;
        SENTRY_SAMPLE_RATE: string;
    }
}

export const BOOKING_API_URL = window.BOOKING_API_URL || "";
export const CONFERENCE_CONTROL_API_URL = window.CONFERENCE_CONTROL_API_URL || "";
export const CONFERENCE_PLAYBACK_BOT_API_URL = window.CONFERENCE_PLAYBACK_BOT_API_URL || "";
export const PHONE_NUMBER_API_URL = window.PHONE_NUMBER_API_URL || "";
// TODO: Better to rename it to ADMIN_UI_URL
export const ADMIN_URL = window.ADMIN_UI_URL;
// TODO: Better to rename it to REGISTRATION_UI_URL
export const REGISTRATION_URL = window.REGISTRATION_UI_URL;

export const ENABLE_VIDEO = window.CONFERENCING_ENABLE_VIDEO !== undefined ? (window.CONFERENCING_ENABLE_VIDEO === "true") : false;
export const ACCOUNT_SID = window.TWILIO_ACCOUNT_SID;
export const DEBUG_LEVEL: LogLevel = window.CONFERENCING_DEBUG_LEVEL as LogLevel || "silent";

// Sentry config
export const SENTRY_ENVIRONMENT = window.SENTRY_ENVIRONMENT || "local";
export const SENTRY_TARGET = Buffer.from(window.SENTRY_TARGET_CONFERENCE_UI, 'base64').toString('utf-8');
export const SENTRY_TRACE_SAMPLE_RATE = parseFloat(window.SENTRY_TRACE_SAMPLE_RATE || "");
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE = parseFloat(window.SENTRY_REPLAY_SESSION_SAMPLE_RATE || "");
export const SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE = parseFloat(window.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || "");
export const SENTRY_SAMPLE_RATE = window.SENTRY_SAMPLE_RATE === undefined ? undefined : parseFloat(window.SENTRY_SAMPLE_RATE);

// Commit hash can be added during code generation as commit hash is a part of compilation, not part of application runtime
export const COMMIT_SHA = process.env.REACT_APP_COMMIT_SHA || undefined;

// Twilo Video logging
export const DEBUG_TWILIO_VIDEO = window.DEBUG_TWILIO_VIDEO === "true";

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = "TwilioVideoApp-selectedAudioInput";
export const SELECTED_AUDIO_OUTPUT_KEY = "TwilioVideoApp-selectedAudioOutput";
export const SELECTED_VIDEO_INPUT_KEY = "TwilioVideoApp-selectedVideoInput";

// Used so we can store and recover app state when the page is refreshed
export const APP_STATE_SESSION_STORAGE_KEY = "notified_app_state";

export const PHONE_PARTICIPANT_PREFIX = "phone";
export const PHONEOUT_PARTICIPANT_PREFIX = "phoneout";
export const ID3AS_ENCODER_PARTICIPANT_PREFIX = "id3as-encoder";
export const PLAYBACKBOT_IDENTITY = "playback-bot";

export const IDENTITY_SEPERATOR = "!";

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = "TwilioVideoApp-selectedBackgroundSettings";

export const ALLOWED_FILE_TYPES = "audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv";

export const LEFT_MIN_WIDTH = 470;
export const RIGHT_MIN_WIDTH = 830;

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
    width: 1280,
    height: 720,
    frameRate: 24
};
