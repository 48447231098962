import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paginator, SyncMapItem } from "twilio-sync";
import { useAppState } from "../useAppState/useAppState";
import { useLocalAudioToggle } from "../useLocalAudioToggle/useLocalAudioToggle";
import { useSyncContext } from "../useSyncContext/useSyncContext";
import { useToasterContext } from "../useToasterContext/useToasterContext";

export const useSpeakersMap = (): SyncMapItem[] => {
	const { speakersMap } = useSyncContext();
	const [speakers, setSpeakers] = useState<SyncMapItem[]>([]);
	const [isAudioEnabled, toggleAudio] = useLocalAudioToggle();
	const { appState } = useAppState();
	const { participantId } = appState;
	const { toaster } = useToasterContext();
	const { t } = useTranslation();

	const pageHandler = useCallback((paginator: Paginator<SyncMapItem>): any => {
		setSpeakers(paginator.items);
		return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
	}, []);

	const getDuplicateSpeakers: (speakers: SyncMapItem[]) => SyncMapItem[] = (speakers) => {
		const objectCount: Record<string, number> = {};
		const duplicates: SyncMapItem[] = [];

		for (const viewer of speakers) {
			if (objectCount[viewer.key]) {
				if (objectCount[viewer.key] === 1) {
					duplicates.push(viewer);
				}
				objectCount[viewer.key] = objectCount[viewer.key] + 1;
			} else {
				objectCount[viewer.key] = 1;
			}
		}

		return duplicates;
	};

	const handleItemAdded = useCallback((args: any) => {
		console.log("(useSpeakersMap) - Item Added", args, args?.item?.data);

		setSpeakers(prevSpeakers => [args.item, ...prevSpeakers]);

		const duplicateSpeakers = getDuplicateSpeakers([args.item, ...speakers]);

		if (duplicateSpeakers.length) {
			console.error("(useSpeakersMap) - Duplicate speakers detected", duplicateSpeakers.map(viewer => viewer.key));
		}
	}, [speakers]);

	const handleItemRemoved = useCallback((args: any) => {
		console.log("(useSpeakersMap) - Item Removed", args.key);

		setSpeakers(prevSpeakers => prevSpeakers.filter(i => i.key !== args.key));
	}, []);

	const handleItemUpdated = useCallback((args: any) => {
		try {
			console.log("(useSpeakersMap) - Item Updated", args);

			setSpeakers(prevSpeakers => [args.item, ...prevSpeakers.filter(i => i.key !== args.item.key)]);

			const isSelf = participantId === args.item.key.split("!")[0];

			if (isSelf) {
				const shouldMute = args.item.data.muted === true && isAudioEnabled;
				const shouldUnmute = args.item.data.muted === false && !isAudioEnabled;

				if (shouldMute || shouldUnmute) {
					console.log(`(useSpeakersMap) - Local mute changed by host - Is currently enabled: ${isAudioEnabled} - Payload muted: ${args.item.data.muted} - Should mute: ${shouldMute} - Should unmute: ${shouldUnmute}`);

					toggleAudio();

					toaster.push({
						id: "mute-unmute-audio",
						message: isAudioEnabled
							? t("conference-ui.toasters.muted-by-host")
							: t("conference-ui.toasters.unmuted-by-host"),
						variant: "neutral",
						dismissAfter: 2000
					});

					setTimeout(() => {
						toaster.pop("mute-unmute-audio");
					}, 2000);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}, [isAudioEnabled, participantId, t, toaster, toggleAudio]);

	useEffect(() => {
		if (!speakersMap) {
			return;
		}

		// Sets the list on load. Limiting to first 100 speakers who are raising their hand
		speakersMap.getItems({ pageSize: 100 }).then(pageHandler);
	}, [speakersMap, pageHandler]);

	useEffect(() => {
		if (!speakersMap) {
			return;
		}

		speakersMap.on("itemAdded", handleItemAdded);
		speakersMap.on("itemRemoved", handleItemRemoved);
		speakersMap.on("itemUpdated", handleItemUpdated);

		return () => {
			speakersMap.off("itemAdded", handleItemAdded);
			speakersMap.off("itemRemoved", handleItemRemoved);
			speakersMap.off("itemUpdated", handleItemUpdated);
		};
	}, [speakersMap, handleItemAdded, handleItemRemoved, handleItemUpdated]);

	return speakers;
}
