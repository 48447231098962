import { LocalAudioTrack } from "twilio-video";
import { useCallback, useEffect } from "react";
import { useIsTrackEnabled } from "../useIsTrackEnabled/useIsTrackEnabled";
import { useVideoContext } from "../useVideoContext/useVideoContext";
import { useAppState } from '../useAppState/useAppState';


export const useLocalAudioToggle = (): readonly [boolean, () => void] => {
	const { localTracks } = useVideoContext();
	const audioTrack = localTracks.find(track => track.kind === "audio") as LocalAudioTrack;
	const isEnabled = useIsTrackEnabled(audioTrack);
	const { appDispatch } = useAppState();

	const toggleAudioEnabled = useCallback(() => {
		if (!audioTrack) {
			return;
		}

		if (audioTrack.isEnabled) {
			console.log("(useLocalAudioToggle) - Muting local participant");
			audioTrack.disable();
		} else {
			console.log("(useLocalAudioToggle) - Unmuting local participant");
			audioTrack.enable();
		}
	}, [audioTrack]);

	useEffect(() => {
		appDispatch({ type: "set-is-muted", isMuted: !isEnabled });
	}, [isEnabled, appDispatch]);

	return [isEnabled, toggleAudioEnabled] as const;
}
