import { Box } from "@twilio-paste/box";
import { AudioLevelIndicator } from "../../../AudioLevelIndicator/AudioLevelIndicator";
import { usePublications } from "../../../../hooks/usePublications/usePublications";
import { useTrack } from "../../../../hooks/useTrack/useTrack";
import { Participant as IParticipant, LocalAudioTrack, RemoteAudioTrack } from "twilio-video";
import { NetworkQualityLevel } from "../../../NetworkQualityLevel/NetworkQualityLevel";
import { FC, useEffect, useState } from "react";
import { Button } from "@twilio-paste/core/dist/button";
import { PLAYBACKBOT_IDENTITY } from "../../../../constants";
import { useConferenceControlContext } from "../../../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useToasterContext } from "../../../../hooks/useToasterContext/useToasterContext";
import { splitIdentity, stripPhone } from "../../../../core/utils";
import { useTranslation } from "react-i18next";

interface ParticipantListItemProps {
	participant: IParticipant;
	isLocal: boolean;
	isPhone: boolean;
}

export const ParticipantIndicators: FC<ParticipantListItemProps> = ({ participant, isLocal, isPhone }: ParticipantListItemProps) => {
	const { t } = useTranslation();
	const publications = usePublications(participant);
	const audioPublication = publications.find(p => p.kind === "audio");
	const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
	const { muteTelephony } = useConferenceControlContext();
	const [loading, setLoading] = useState(false);
	const { toaster } = useToasterContext();

	const toggleMuteSpeaker = async () => {
		setLoading(true);

		const hasNoAudioTrack = audioTrack === null;
		const hasMutedAudioTrack = audioTrack && audioTrack.isEnabled === false;
		const shouldUnmute = hasNoAudioTrack || hasMutedAudioTrack;
		const shouldMute = !shouldUnmute;

		try {
			await muteTelephony(participant.identity, shouldMute);

			setLoading(false);
		} catch (err) {
			console.error(`(ParticipantIndicators) - Error toggling mute state for "${participant.identity}"`, err);
			setLoading(false);

			const identity = splitIdentity(stripPhone(participant.identity));

			toaster.push({
				message: shouldMute
					? t("conference-ui.toasters.mute-error", { user: identity })
					: t("conference-ui.toasters.unmute-error", { user: identity }),
				variant: "error",
				dismissAfter: 5000
			});
		}
	};

	useEffect(() => {
		if (!audioTrack) {
			return;
		}
		console.log(`(ParticipantIndicators) - Audio has been ${audioTrack?.isEnabled ? "enabled" : "disabled"} for participant: ${participant.identity}`);
	}, [audioTrack, participant]);

	const isPlaybackBot = participant.identity.startsWith(PLAYBACKBOT_IDENTITY);

	return (
		<Box display="flex" flexDirection="row" height="100%" columnGap="space30" alignItems="center">
			{(!isPhone && !isPlaybackBot) && <NetworkQualityLevel participant={participant} />}
			{(isLocal || isPlaybackBot) ?
				<AudioLevelIndicator audioTrack={audioTrack} isPhone={isPhone} color="black" /> :
				<Button onClick={toggleMuteSpeaker} variant="secondary" size="icon_small" loading={loading}>
					<AudioLevelIndicator audioTrack={audioTrack} color="black" />
				</Button>
			}
		</Box >
	);
}